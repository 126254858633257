
import BasicLayout from "../../layout/basic"
// 其他模块
export default {
	path: "/Index",
	component: BasicLayout,
	name: "Index",
	redirect: "/index",
	children: [{
		path: "/index",
		name: "index",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/index/index")
	}, {
		path: "/Jrindex",
		name: "Jrindex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Jrindex/index")
	}, {
		path: "/Rqindex",
		name: "Rqindex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/Rqindex/index")
	},
	{
		path: "/Mfindex",
		name: "Mfindex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Mfindex/index")
	},{
		path: "/Fyindex",
		name: "Fyindex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/FyBoiler/Fyindex/index")
	},
	{
		path: "/Jhindex",
		name: "Jhindex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/JhBoiler/Jhindex/index")
	},
	{
		path: "/Jhyxpj",
		name: "Jhyxpj",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/JhBoiler/Jhyxpj/index")
	},
	{
		path: "/Jhindex_Zy",
		name: "Jhindex_Zy",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/JhBoiler/Jhindex/index_Zy")
	},
	{
		path: "/Jhindex2",
		name: "Jhindex2",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/JhBoiler/Jhindex/index2")
	},
	{
		path: "/Jhindex3",
		name: "Jhindex3",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/JhBoiler/Jhindex/index3")
	},
	{
		path: "/6DjlIndex",
		name: "6DjlIndex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/6DBoiler/6Djl/index")
	},{
		path: "/6DfyfIndex",
		name: "6DfyfIndex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/6DBoiler/6Dfyf/index")
	},{
		path: "/6Dqhhm",
		name: "6Dqhhm",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/6DBoiler/6Dqhhm/index")
	},{
		path: "/6Dpub",
		name: "6Dpub",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/6DBoiler/6Dpub/index")
	},
	,{
		path: "/6Dwlnh",
		name: "6Dwlnh",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/6DBoiler/6Dwlnh/index")
	},{
		path: "/GzIndex",
		name: "GzIndex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/GzBoiler/GzIndex/index")
	},{
		path: "/GzIndex1",
		name: "GzIndex1",
		meta: { 
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/GzBoiler/GzIndexOther/index")
	},
	{
		path: "/Gzyxpj",
		name: "Gzyxpj",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/GzBoiler/Gzyxpj/index")
	},
	{
		path: "/Gzyxpjjlt",
		name: "Gzyxpjjlt",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/GzBoiler/Gzyxpj/indexjlt")
	},
	{
		path: "/GzjltA",
		name: "GzjltA",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/GzBoiler/Gzjlt/indexA")
	},
	{
		path: "/GzjltB",
		name: "GzjltB",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/GzBoiler/Gzjlt/indexB")
	},
	{
		path: "/firstcontol",
		name: "firstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/firstcontol/index")
	}, {
		path: "/trendGroup",
		name: "trendGroup",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/trendGroup/index")
	},
	{
		path: "/switchPage",
		name: "switchPage",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/switchPage/index")
	}, {
		path: "/Security",
		name: "Security",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Security/index")
	}, {
		path: "/Evaluate",
		name: "Evaluate",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Evaluate/index")
	}, {
		path: "/kfirstcontol",
		name: "kfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/kfirstcontol/index")
	},
	{
		path: "/rqkfirstcontol",
		name: "rqkfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/RqBoiler/Rqkfirstcontol/index")
	},
	{
		path: "/rqkfirstcontol1",
		name: "rqkfirstcontol1",
		meta: {
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/RqBoiler/Rqkfirstcontol1/index")
	},

	{
		path: "/rqRfirstcontol",
		name: "rqRfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqRfirstcontol/index")
	},
	{
		path: "/mfRfirstcontol",
		name: "mfRfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/MfRfirstcontol/index")
	},
	{
		path: "/mfkfirstcontol",
		name: "mfkfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/Mfkfirstcontol/index")
	},
	{
		path: "/jrRfirstcontol",
		name: "jrRfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},
		component: () => import("@/views/Jrviews/JrRfirstcontol/index")
	},
	{
		path: "/jrqkfirstcontol",
		name: "jrkfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Jrviews/Jrkfirstcontol/index")
	}, {
		path: "/Cfbkfirstcontol",
		name: "Cfbkfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/Cfbkfirstcontol/index")
	}, {
		path: "/CfbRfirstcontol",
		name: "CfbRfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbRfirstcontol/index")
	},
	{
		path: "/Rfirstcontol",
		name: "Rfirstcontol",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Rfirstcontol/index")
	},
	{
		path: "/RqSecurity",
		name: "RqSecurity",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqSecurity/index")
	},
	{
		path: "/Jraqsz",
		name: "Jraqsz",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Jrviews/Jraqsz/index")
	}, {
		path: "/Jryxpj",
		name: "Jryxpj",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Jrviews/Jryxpj/index")
	}, {
		path: "/Jrswitch",
		name: "Jrswitch",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/Jrviews/Jrswitch/JRL_switch")
	},
	{
		path: "/RqSwitchPage",
		name: "RqSwitchPage",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqSwitchPage/index")
	}, {
		path: "/RqOnlineService",
		name: "RqOnlineService",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqOnlineService/index")
	}, {
		path: "/RqCommonParameters",
		name: "RqCommonParameters",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqCommonParameters/index")
	}, {
		path: "/RqOperationAssessment",
		name: "RqOperationAssessment",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqOperationAssessment/index")
	}, {
		path: "/RqSoftMeasurement",
		name: "RqSoftMeasurement",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqSoftMeasurement/index")
	}, {
		path: "/RqDesulphurization",
		name: "RqDesulphurization",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqDesulphurization/index")
	}, {
		path: "/RqAirSupply",
		name: "RqAirSupply",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqAirSupply/index")
	}, {
		path: "/RqMainTemperature",
		name: "RqMainTemperature",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqMainTemperature/index")
	}, {
		path: "/RqSteamDrum",
		name: "RqSteamDrum",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqSteamDrum/index")
	}, {
		path: "/RqSoftNumber",
		name: "RqSoftNumber",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqSoftNumber/index")
	}, {
		path: "/RqParameter3",
		name: "RqParameter3",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqParameter3/index")
	}, {
		path: "/RqParameter4",
		name: "RqParameter4",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqParameter4/index")
	}, {
		path: "/RqParameter5",
		name: "RqParameter5",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/RqBoiler/RqParameter5/index")
	}, {
		path: "/Cfbindex",
		name: "Cfbindex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/Cfbindex/index")
	}, {
		path: "/CfbOnlineService",
		name: "CfbOnlineService",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbOnlineService/index")
	}, {
		path: "/CfbParameter3",
		name: "CfbParameter3",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter3/index")
	}, {
		path: "/CfbParameter7",
		name: "CfbParameter7",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter7/index")
	}, {
		path: "/CfbSecurity",
		name: "CfbSecurity",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbSecurity/index")
	}, {
		path: "/CfbParameter2",
		name: "CfbParameter2",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter2/index")
	}, {
		path: "/CfbParameter1",
		name: "CfbParameter1",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter1/index")
	}, {
		path: "/CfbParameterSmoke2",
		name: "CfbParameterSmoke2",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameterSmoke2/index")
	}, {
		path: "/CfbParameter4",
		name: "CfbParameter4",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter4/index")
	}, {
		path: "/CfbParameter5",
		name: "CfbParameter5",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter5/index")
	}, {
		path: "/CfbParameter6",
		name: "CfbParameter6",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter6/index")
	}, {
		path: "/CfbParameter8",
		name: "CfbParameter8",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbParameter8/index")
	}, {
		path: "/CfbBedTemperature",
		name: "CfbBedTemperature",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbBedTemperature/index")
	}, {
		path: "/CfbAirSoft",
		name: "CfbAirSoft",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbAirSoft/index")
	}, {
		path: "/CfbCommonSetting",
		name: "CfbCommonSetting",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbCommonSetting/index")
	},
	{
		path: "/CfbSwitchPage",
		name: "CfbSwitchPage",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbSwitchPage/index")
	},
	{
		path: "/CfbOperationAssessment",
		name: "CfbOperationAssessment",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbOperationAssessment/index")
	}, {
		path: "/CfbSoftMeasure",
		name: "CfbSoftMeasure",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbSoftMeasure/index")
	}, {
		path: "/CfbDataStatistics",
		name: "CfbDataStatistics",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbDataStatistics/index")
	}, {
		path: "/CfbDesulphurization",
		name: "CfbDesulphurization",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/CfbBoiler/CfbDesulphurization/index")
	},
	{
		path: "/MFZFXT",
		name: "MFZFXT",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/MFZFXT/index")
	}, {
		path: "/MFECF",
		name: "MFECF",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/MFECF/index")
	}, {
		path: "/MFYXPJ",
		name: "MFYXPJ",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/MFYXPJ/index")
	}, {
		path: "/MFSwitch",
		name: "MFSwitch",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/MFSwitch/index")
	}, {
		path: "/MFSecurity",
		name: "MFSecurity",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/MFSecurity/index")
	}, {
		path: "/MFTLTX",
		name: "MFTLTX",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/MfBoiler/MFTLTX/index")
	}, {
		path: "/FyYjqt",
		name: "FyYjqt",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/FyBoiler/FyYjqt/index")
	}
	, {
		path: "/Jlyxpj",
		name: "Jlyxpj",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/JlBoiler/Jlyxpj/index")
	}
	, {
		path: "/Jlindex",
		name: "Jlindex",
		meta: {
			mainCss: {
				width: "100%"
			}
		},

		component: () => import("@/views/JlBoiler/Jlindex/index")
	}

	]
}
// beforeEnter(to, from, next){
// 	console.log(from)
// 	if(from.path=='/'){ //当前页面路由
// 				// next({replace: true,redirect: '/a'});
// 			}else {
// 		// next()
// 	}

// },