let email = /^(\w+\.?)*\w+@(?:\w+\.)\w+$/    //邮箱
let tel = /^1[3456789]\d{9}$/        //手机号码
let name = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,10}$/;   //姓名
let regId = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/ //身份证
let username = /^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,19}$/ //账户名
let psd = /^[0-9]{6,20}$/  //校验密码：只能输入6-20个数字
let deviceNum = /^[0-9a-zA-Z]{0,20}$/  //数字和字母
let account  =  /^[0-9\u4e00-\u9fa5a-zA-Z]{2,19}$/
let FormValidate = (function () {
    // eslint-disable-next-line
    function FormValidate() {}
    // From表单验证规则  可用于公用的校验部分
    FormValidate.Form = function () {
        return {
            // 姓名的验证规则
            Name: function (rule, value, callback) {
                if (!value) {
                    return callback(new Error('姓名不能为空'))
                }
                if (!name.test(value)) {
                    callback(new Error('请输入正确姓名!'))
                } else {
                    callback()
                }
            },
             // 账户的验证规则
             Account: function (rule, value, callback) {
                if (!value) {
                    return callback(new Error('账户不能为空'))
                }
                if (!account.test(value)) {
                    callback(new Error('请输入正确账户!'))
                } else {
                    callback()
                }
            },
            // 邮箱的正则
            Email: function (rule, value, callback) {
                if (!value) {
                    return callback(new Error('邮箱不能为空'))
                }
                if (!email.test(value)) {
                    callback(new Error('请输入正确的邮箱!'))
                } else {
                    callback()
                }
            },
            // 身份证的验证规则
            ID: function (rule, value, callback) {
                if (!value) {
                    return callback(new Error('身份证不能为空'))
                }
                if (!regId.test(value)) {
                    callback(new Error('请输入正确的二代身份证号码'))
                } else {
                    callback()
                }
            },
            // 电话号码的验证
            Tel: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('电话不能为空'))
                }
                if (!tel.test(value)) {
                    callback(new Error('请正确填写电话号码'))
                } else {
                    callback()
                }
            },
            // 用户名验证
             Username: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('用户名不能为空'))
                }
                if (!username.test(value)) {
                    callback(new Error('请使用中文'))
                } else {
                    callback() 
                }
            },
            deviceNum: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('设备号不能为空'))
                }
                if (!deviceNum.test(value)) {
                    callback(new Error('请正确填写设备号'))
                } else {
                    callback()
                }
            },
             // 密码验证
             Psd: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('密码不能为空'))
                }
                if (!psd.test(value)) {
                    callback(new Error('只能输入6-20个数字'))
                } else {
                    callback()
                }
            }
        }
    }
   
    // FromOne表单验证规则  用于FromOne个性化的校验
    FormValidate.FormOne = function () {
        return {
            // 姓名的验证规则
            Name: function (rule, value, callback) {
                if (!value) {
                    return callback(new Error('姓名不能为空'))
                }
                if (!name.test(value)) {
                    callback(new Error('请输入正确姓名!'))
                } else {
                    callback()
                }
            }
        }
    }
    return FormValidate
  }())
  exports.FormValidate = FormValidate