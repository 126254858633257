import OtherLayout from "../../layout/otherPage"
// 通讯总览页
export default {
    path: "/otherPage",
    component: OtherLayout,
    redirect: "/overview",
    alwaysShow: true,
    name: "otherPage",
    children: [
        {
            path: "/overview",
            name: "overview",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/otherPage/overview")
        },{
            path: "/userAdmin",
            name: "userAdmin",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/userAdmin/index")
        },
        {
            path: "/userotherPage",
            name: "userotherPage",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/useotherPage/index.vue") 
        },
        {
            path: "/userotherPage",
            name: "userotherPage",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/useotherPage/index.vue") 
        },
        {
            path: "/userotherPage_zt",
            name: "userotherPage",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/useotherPage/index_zt.vue") 
        },
		{
		    path: "/CeiPage",
		    name: "CeiPage",
		    meta: {
		        backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
		    },
		    component: () => import("@/views/CeiPage/index.vue") 
		},
        {
		    path: "/CeiPage1",
		    name: "CeiPage1",
		    meta: {
		        backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
		    },
		    component: () => import("@/views/CeiPage/index1.vue") 
		},
        {
            path: "/CEIStatistics",
            name: "CEIStatistics",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/CEIStatistics/index.vue") 
        },
		{
		    path: "/Solutionsum",
		    name: "Solutionsum",
		    meta: {
		        backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
		    },
		    component: () => import("@/components/Solutionsum")
		}
        
    ]
}