import http from "../../utils/http"

/**
 * 登录
 */
// 这事测试
export function loginUser(params) {
    return http({
        url: "/login",
        method:'post',
        data: params
    })
}
export function loginAdmin(params) {
    return http({
        url: "/admin/login",
        method:'post',
        data: params
    })
} 
export function getlogin(params) {
    return http({
        url: "/user/g_u_p",
        method:'post',
        data: params
    })
} 
//添加日志
export function log_add(params) {
	return http({
		url: "log_add",
		method: 'POST',
		data: params,

	})
}
//查询日志
export function log_show(params) {
	return http({
		url: "log_show",
		method: 'POST',
		data: params,

	})
}