import LoginLayout from "../../layout/login"

// 登录模块
export default {
    path: "/",
    component: LoginLayout,
    redirect: "/login",
    alwaysShow: true,
    name: "Auth",
    children: [
        {
            path: "/login",
            name: "login",
            meta: {
                module: "login",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/auth/login")
        },{
            path: "/adminLogin",
            name: "adminLogin",
            meta: {
                module: "adminLogin",
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/adminLogin/adminLogin")
        },
		{
			path: "/show",
			name: "show",
			meta: {
				mainCss: {
					width: "100%"
				}
			},
		
			component: () => import("@/views/show/index")
		},
    ]
}
