import Vue from "vue"
import router from "./router"
import VueMeta from "vue-meta"
import Element from "element-ui"
import * as echarts from 'echarts';
import BaiduMap from 'vue-baidu-map'

Vue.use(Element)

Vue.use(BaiduMap, {
  ak: 'Tt6XNYvuaTBKCqZQsoqS7TOPjR8uck8k'
});

import "element-ui/lib/theme-chalk/index.css"
import "./assets/styles/main.scss"
import "./assets/styles/element-variables.scss"
import './assets/styles/iconfont.css' //阿里云图标
import './utils/directives.js' //弹框拖拽
import dataV from '@jiaminghi/data-view'
import App from "./App.vue"

import Util from "./utils/util"
import Config from "./utils/config.js"

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts

// import global from '@/utils/ws.js'
// import global1 from '@/utils/ws1.js'

Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
// Vue.prototype.global = global
// Vue.prototype.global1 = global1

// 常用工具函数
Vue.prototype.$util = Util

Vue.prototype.$config = Config
Vue.use(dataV)
Vue.use(VueMeta)
var EventBus = new Vue();

Object.defineProperties(Vue.prototype, {
  $bus: {
    get: function () {
      return EventBus
    }
  }
})
window.vue = new Vue({
    router,
    render: h => h(App)
}).$mount("#app")
// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {  // 需要权限
//       //判断当前是否拥有权限
//       if (localStorage.getItem('UserInfo')) {
//           next();
//       } else {  // 无权，跳转登录
//           next({
//               path: '/login',
//               query: { redirect: from.fullPath }
//           })
//       }
//   } else {  // 不需要权限，直接访问
//       next();
//   }
// })