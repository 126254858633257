import mapLayout from "../../layout/map"
// 地图页
export default {
    path: "/PerformanceMap",
    component: mapLayout,
    redirect: "/mapIndex",
    alwaysShow: true,
    name: "PerformanceMap",
    children: [
        {
            path: "/mapIndex",
            name: "mapIndex",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				},
                // requireAuth: true
            },
            component: () => import("@/views/PerformanceMap/index")
        },
        {
            path: "/cityMap",
            name: "cityMap",
            meta: {
                backgroundColor: "#fff",
				mainCss:{
					width: "100%"
				}
            },
            component: () => import("@/views/PerformanceMap/cityMap")
        },
		
    ]
}