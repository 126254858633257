import Vue from 'vue'
// 自定义元素实现弹框拖拽[重点]

Vue.directive('draw', {

  inserted: function(el, binding, vNode) {
        if(el.offsetWidth == 588){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-208px 0 0 -300px')
        }else if(el.offsetWidth == 694){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-292px 0 0 -347px')
        }else if(el.offsetWidth == 1024 && el.offsetHeight == 600){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-300px 0 0 -512px')
        }else if(el.getAttribute("id") == "ParameterYh"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -33vw')
        }
        else if( el.getAttribute("id") == "param"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-40vh 0 0 -36vw')
        }else if(el.getAttribute("id") == "Evaluate"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:8vh;left:0')
        }
        else if(el.getAttribute("id") == "Historical"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-41vh 0 0 -33vw')
        }else if(el.offsetWidth == 1256){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-444px 0 0 -628px')
        }else if(el.getAttribute("id") == "param01"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -512px')
        }
        else if(el.getAttribute("id") == "Security"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:8vh;left:0;')
        }else if(el.getAttribute("id") == "Switchpage"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-25% 0 0 -29vw')
        }else if(el.getAttribute("id") == "px16"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-45.5vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "Jrwtdata"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-50vh 0 0 -50vw')
        }else if(el.getAttribute("id") == "JrAssessment"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-42vh 0 0 -50vw')
        }else if(el.getAttribute("id") == "Jrlkq"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-38vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "JrparmsGroup"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-45.5vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "jrlcysz" || el.getAttribute("id") == "jrlmqzg"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "jrlpamrm01"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-32.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "jrlmyzg" || el.getAttribute("id") == "jrlsjcj"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-42.5vh 0 0 -39vw')
        }else if(el.getAttribute("id") == "Jrlme"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "jrl05"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -27.5vw')
        }else if(el.getAttribute("id") == "param1-4"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }
        else if(el.getAttribute("id") == "RqAirSupply"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "RqMainTemperature"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "RqOnlineService"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-30vh 0 0 -18vw')
        }else if(el.getAttribute("id") == "RqParameter3"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "RqParameter4"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-35vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "RqParameter5"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-45.5vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "RqSoftMeasurement"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "RqSoftNumber"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-35vh 0 0 -26.5vw')
        }
        else if(el.getAttribute("id") == "RqSteamDrum"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }
        else if(el.getAttribute("id") == "CfbAirSoft"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "CfbBedTemperature"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "CfbCommonSetting"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "CfbOnlineService"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-30vh 0 0 -18vw')
        }else if(el.getAttribute("id") == "CfbParameter1"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        } else if(el.getAttribute("id") == "CfbParameter2"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }
        else if(el.getAttribute("id") == "CfbParameterSmoke2"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "CfbParameter3"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "CfbParameter4"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-45.5vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "CfbParameter5"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "CfbParameter6"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-45.5vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "CfbParameter7"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-45.5vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "CfbParameter8"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "CfbSoftMeasure"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "manyManual"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-45vh 0 0 -47.5vw')
        }else if(el.getAttribute("id") == "manyManual1"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-20vh 0 0 -16.5vw')
        }else if(el.getAttribute("id") == "manyManual2"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-23vh 0 0 -32vw')
        }else if(el.getAttribute("id") == "manyManual4"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-44vh 0 0 -32vw')
        }else if(el.getAttribute("id") == "manyManual3"){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-23vh 0 0 -47.5vw')
        }
        else if(el.getAttribute("id") == "mflmac" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -26.5vw')
        }
        else if(el.getAttribute("id") == "mflfmazx" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:45%;left:50%;margin:-39vh 0 0 -26.5vw')
        }
        else if(el.getAttribute("id") == "mfzqfozx" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:45%;left:50%;margin:-39vh 0 0 -26.5vw')
        }
        else if(el.getAttribute("id") == "ZQTC" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:45%;left:50%;margin:-42.5vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "ZQPC" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:45%;left:50%;margin:-39vh 0 0 -41.5vw')
        }else if(el.getAttribute("id") == "QBWC" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -27.5vw')
        }else if(el.getAttribute("id") == "YQOC" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "YCFC" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-33.5vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "LTPC" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "TLTXC" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-39vh 0 0 -26.5vw')
        }else if(el.getAttribute("id") == "Cfbdata" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-15vh 0 0 -47vw')
        }else if(el.getAttribute("id") == "RqCommonParameters" ){
            el.setAttribute('style', 'position: fixed; z-index: 999;top:50%;left:50%;margin:-27vh 0 0 -18vw')
        }
        
  },

  bind: function(el, bindding, vNode) {
    el.setAttribute('draggable', true)

    let left, top, width, height

    el._dragstart = function(event) {
      event.stopPropagation()
        // console.log(event)
      left = event.clientX - el.offsetLeft
      top = event.clientY - el.offsetTop

//       width = el.offsetWidth

//       height = el.offsetHeight
     el.style.margin = '0'   
     el.style.zIndex++
    }

    el._checkPosition = function() { // 防止被拖出边界
      const width = el.offsetWidth

      const height = el.offsetHeight

      let left = Math.min(el.offsetLeft, document.body.clientWidth - width)
        
      left = Math.max(0, left)
      let top = Math.min(el.offsetTop, document.body.clientHeight - height)

      top = Math.max(0, top)

      el.style.left = left + 'px'

      el.style.top = top + 'px'

//       el.style.width = width + 'px'

//       el.style.height = height + 'px'
      el.style.margin =  '0 auto'
    }

    el._dragEnd = function(event) {
      event.stopPropagation()
        console.log(event)
      left = event.screenX - left
       
      top = event.screenY - top - 79

      el.style.left = left + 'px'

      el.style.top = top + 'px'

//       el.style.width = width + 'px'

//       el.style.height = height + 'px'
      el._checkPosition()
    }

    el._documentAllowDraop = function(event) {

      event.preventDefault()

    }

    document.body.addEventListener('dragover', el._documentAllowDraop)

    el.addEventListener('dragstart', el._dragstart)

    el.addEventListener('dragend', el._dragEnd)

    window.addEventListener('resize', el._checkPosition)

  },

  unbind: function(el, bindding, vNode) {

    document.body.removeEventListener('dragover', el._documentAllowDraop)

    el.removeEventListener('dragstart', el._dragstart)

    el.removeEventListener('dragend', el._dragEnd)

    window.removeEventListener('resize', el._checkPosition)

    delete el._documentAllowDraop

    delete el._dragstart

    delete el._dragEnd

    delete el._checkPosition

  }

})

// v-dialogDrag: 弹窗拖拽

Vue.directive('dialogDrag', {

  bind(el, binding, vnode, oldVnode) {

    const dialogHeaderEl = el.querySelector('.el-dialog__header')

    const dragDom = el.querySelector('.el-dialog')

    dialogHeaderEl.style.cursor = 'move'

    // 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);

    const sty = dragDom.currentStyle || window.getComputedStyle(dragDom, null)

    dialogHeaderEl.onmousedown = (e) => {

      // 鼠标按下，计算当前元素距离可视区的距离

      const disX = e.clientX - dialogHeaderEl.offsetLeft

      const disY = e.clientY - dialogHeaderEl.offsetTop

      // 获取到的值带px 正则匹配替换

      let styL, styT

      // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px

      if (sty.left.includes('%')) {

        styL = +document.body.clientWidth * (+sty.left.replace(/\%/g, '') / 100)

        styT = +document.body.clientHeight * (+sty.top.replace(/\%/g, '') / 100)

      } else {

        styL = +sty.left.replace(/\px/g, '')

        styT = +sty.top.replace(/\px/g, '')

      }

      document.onmousemove = function(e) {

        // 通过事件委托，计算移动的距离

        const l = e.clientX - disX

        const t = e.clientY - disY

        // 移动当前元素

        dragDom.style.left = `${l + styL}px`

        dragDom.style.top = `${t + styT}px`

        // 将此时的位置传出去

         binding.value({x:e.pageX,y:e.pageY})

      }

      document.onmouseup = function(e) {

        document.onmousemove = null

        document.onmouseup = null

      }

    }

  }

})

// v-dialogDragWidth: 弹窗宽度拖大 拖小

Vue.directive('dialogDragWidth', {

  bind(el, binding, vnode, oldVnode) {

    const dragDom = binding.value.$el.querySelector('.el-dialog')

    el.onmousedown = (e) => {

      // 鼠标按下，计算当前元素距离可视区的距离

      const disX = e.clientX - el.offsetLeft

      document.onmousemove = function(e) {

        e.preventDefault() // 移动时禁用默认事件

        // 通过事件委托，计算移动的距离

        const l = e.clientX - disX

        dragDom.style.width = `${l}px`

      }

      document.onmouseup = function(e) {

        document.onmousemove = null

        document.onmouseup = null

      }

    }

  }

})
