<template>
    <el-container class="map">
         <transition name="slide"><router-view /></transition>
    </el-container>
</template>
<script>
    export default {
        name: "Layout",
        components: {
        },
        created() {
        },
        mounted() {},
        computed: {
        },

        watch: {},
        methods: {}
    }
</script>