import http from "../../utils/http"


/**
 * 获取燃烧流程数据接口
 */

export function getIndexInfo(params) {
	return http({
		url: "/actual/" + params.cp_type + '/' + params.device,
		method: 'get',
		data: params,

	})
}
/**
 *  历史趋势下置
 */
export function gethistoryInfo(params) {
	let url
	if (params.start_time) {
		url = "/history?device=" + params.device + '&mark=' + params.mark + '&name=' + params.name + '&node=' + params
			.node + '&end_time=' + params.end_time + '&start_time=' + params.start_time + '&cp_type=' + params.cp_type
	} else {
		url = "/history?device=" + params.device + '&mark=' + params.mark + '&name=' + params.name + '&node=' + params
			.node + '&cp_type=' + params.cp_type
	}
	return http({
		url: url,
		method: 'get',
		data: params,

	})
}
// 下置操作
export function downAction(params) {
	return http({
		url: "/down",
		method: 'post',
		data: params

	})
}
// 手操器下置
export function downmanAction(params) {
	return http({
		url: "/down_man",
		method: 'post',
		data: params

	})
}
// 先控折线图
export function getEchars(params) {
	let url
	if (params.start_time) {
		url = "/xk_history?end_time=" + params.end_time + '&start_time=' + params.start_time + '&cp_type=' + params
			.cp_type
	} else {
		url = "/xk_history?cp_type=" + params.cp_type
	}
	return http({
		url: url,
		method: 'post',
		data: params

	})
}
export function getgroup(params) {
	let url
	if (params.start_time) {
		url = "/list_history?end_time=" + params.end_time + '&start_time=' + params.start_time + '&cp_type=' + params
			.cp_type
	} else {
		url = "/list_history?cp_type=" + params.cp_type
	}
	return http({
		url: url,
		method: 'post',
		data: params

	})
}
// 获取预案
export function planShow(params) {
	return http({
		url: "/mxp/show",
		method: 'post',
		data: params
	})
}
// 添加预案
export function addPlan(params) {
	return http({
		url: "/mxp/add_plan",
		method: 'post',
		data: params
	})
}
// 修改预案名
export function editPlan(params) {
	return http({
		url: "/mxp/edit",
		method: 'post',
		data: params
	})
}
// 删除预案
export function deletPlan(params) {
	return http({
		url: "/mxp/delete",
		method: 'post',
		data: params
	})
}
// 省市地图接口
export function getSpread(params) {
	return http({
		url: "/rts_spread",
		method: 'get',
		data: params
	})
}
// 地图搜索接口 
export function mapSearch(params) {
	return http({
		url: "/map_search",
		method: 'post',
		data: params
	})
}
