<template>
    <el-container>
        <!-- 头部 -->
        <div class="banner"  v-if="is_show">
           <el-header class="flex">
             <div class="el-header-left flex">
                   <img src="../assets/images/rts_Logo.png" alt="">
                   <!-- <div class="el-header-left-loginBox ">
                       <div class="el-header-left-loginBox-text">
                           系统登录
                       </div>
                       <div class="el-header-left-loginBox-username">
                           当前用户：<span>OPS</span>
                       </div>
                   </div> -->
             </div>
             <div class="el-header-con">
                 和隆优化智能优化控制云服务平台（HeroRTS）
             </div>
             <div class="el-header-right flex align-cen">
                 <div class="el-header-right-time">
                    <div> {{nowDate}}</div>
                    <div><span>当前用户：</span>{{UserInfo[1]}}</div>
                 </div>
                 <!-- <el-tooltip placement="left">
                    <div slot="content">{{UserInfo[1]}}</div>
                   <div class="el-header-right-user"  @mouseenter="enter('user')" @mouseleave="leave('user')" ondragstart="return false;">
                     <img :src="userimg" alt="">
                 </div>
                 </el-tooltip> -->
                 <!-- <div class="el-header-right-logout" 
                 @mouseenter="enter('logout')" @mouseleave="leave('logout')" ondragstart="return false;"
                 @click="logout"
                 >
                      <img :src="outimg" alt="">
                 </div> -->
                 </div>   
           </el-header>
        </div>
        <el-main>
            <transition name="slide"><router-view /></transition>
            <!-- 右侧栏 -->
        </el-main>
    </el-container>
</template>
<script>
    export default {
        name: "otherLayout",
        created() {
        },
        data: () => {
            return {
                adList: [],
                is_show: true,
                indexTopAdNum: 0,
                nowDate: "", // 当前日期
                UserInfo:[],
                userimg:require('../assets/images/rts_login.png'),
                outimg:require('../assets/images/rts_Logout.png'),
            }
        },
        mounted() {
             this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
            this.currentTime()
        },
        computed: {},
        watch: {},
        methods: {
            // 退出
            logout(){
				localStorage.clear()
                if(this.$route.path == '/userAdmin' || this.$route.path == '/overview'){
                this.$router.push({ path: '/adminLogin' })
                }else{
                    this.$router.push({ path: '/' })
                }
				
                // localStorage.removeItem('UserInfo');
                // localStorage.removeItem('authInfo')
            },
                  // 鼠标移入
             enter(index){
                 if(index == 'user') return this.userimg = require('../assets/images/rts_loginactive.png')
                 if(index == 'logout') return this.outimg = require('../assets/images/rts_Logoutactive.png')
            },
             leave(index){
                 if(index == 'user') return this.userimg = require('../assets/images/rts_login.png')
                 if(index == 'logout') return this.outimg = require('../assets/images/rts_Logout.png')
      },
         currentTime() {
                setInterval(this.formatDate1, 500);
                },
                    formatDate1() {
                    this.nowDate = this.$util.formatDate('yyyy/MM/dd mm:hh:ss week');
                    }
        },
          // 销毁定时器
  beforeDestroy() {
    if (this.formatDate) {
      clearInterval(this.formatDate); // 在Vue实例销毁前，清除时间定时器
    }
  }
    }
</script>
<style lang="scss" scoped>
        .el-container{
            height: 100vh;
             background: rgba(246,246,246,1);
                    }
    .banner {
        // text-align: center;
        height: 88px;
        position: relative;
       background-image: linear-gradient(-45deg, 
		#0b385f 3%, 
		#005071 19%, 
		#10526b 51%, 
		#205365 100%), 
	linear-gradient(
		#205161, 
		#205161);
	background-blend-mode: normal, normal;
    }
    .el-header {
        padding: 0;
        height: inherit !important;
        .el-header-left{
            height: inherit;
              img{
            width: 300px;
            height: inherit;
        }
        }
        .el-header-left-loginBox{
            height: inherit;
            width: 250px;
            background: $base-color-success;
            height: inherit;
            text-align: center;
            div{
                height: 45px;
                line-height: 45px;
            }
            .el-header-left-loginBox-text{
                color: $base-color-warning;
                font-size: 4vh;

                border-bottom: 1px solid #fff;
            }
            .el-header-left-loginBox-username{
                font-size: 4vh;
                color: #fff;
                span{
                    color: $base-color-warning;
                }
            }
        }
        .el-header-con{
            flex-grow: 1;
            line-height: 88px;
            text-align: center;
            font-size: 2vw;
            color: #FDF061;
            // text-shadow: 5px 5px 5px #000;
        }
        .el-header-right{
           
            height: inherit;
            padding-right: 15px;
            // background: $base-color-success;
           
            .el-header-right-time{
                div{
                    color: #fff;
                    font-size: 16px;
                    padding-right: 30px;
                   span{
                       color: #5AB5CE;
                   }
                }
            }
            .el-header-right-user,.el-header-right-logout{
                cursor: pointer;
                img{
                    width: 60px;
                    height: 60px;
                }
            }

        }
      
    }

</style>
