<template>
  <div class="footer">
    <div class="flex footer_flex">
      <div
        class="footer_tab"
        v-for="(item, index) in fooerList"
        :key="item.id"
        @click="toLink(index, item)"
        :class="{ current: flag == index }"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "rts_footer",
  props: {},
  data() {
    return {
      footerKey: 1,
      current: "current",
      flag: 0,
      fooerList: [],
      deviceInfo: {},
      authInfo: [],
      see: true,
      commotent: false,
    };
  },
  computed: {},
  created() {
    let footerarr = [
      {
        name: "燃烧流程",
        id: 1,
        path: "/",
      },
      {
        name: "安全设置",
        id: 2,
        path: "/",
      },
      {
        name: "运行评价",
        id: 3,
        path: "/",
      },
      {
        name: "切换画面",
        id: 4,
        path: "/",
      },
      {},
      {},
      {},
      {},
      {},
      {},
      {},
    ];
    let obj = localStorage.getItem("deviceType");
    let deviceInfo = JSON.parse(obj);
    this.deviceInfo = deviceInfo;
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.fooerList = footerarr;
    this.$bus.$on("footersendMsg", (msg) => {
      this.flag = msg;
    });
    if (deviceInfo.type == "rfl") {
      if (this.$route.path == "/index") {
        this.flag = 0;
      } else if (this.$route.path == "/switchPage") {
        this.flag = 3;
      } else if (this.$route.path == "/Security") {
        this.flag = 1;
      } else if (this.$route.path == "/Evaluate") {
        this.flag = 2;
      }
    } else if (deviceInfo.type == "rql") {
      footerarr[1].name = "运行考核";
      footerarr[2].name = "安全设置";
      footerarr[3].name = "切换画面";
      footerarr[4] = {
        name: "常用参数",
        id: 5,
        path: "/",
      };
      footerarr[5] = {
        name: "脱硫脱硝",
        id: 6,
        path: "/",
      };
      footerarr[6] = {
        name: "软测量数统",
        id: 7,
        path: "/",
      };
      footerarr[7] = {
        name: "客服",
        id: 8,
        path: "/",
      };
      this.fooerList = footerarr;
      switch (this.$route.path) {
        case "/Rqindex":
          return (this.flag = 0);
        case "/RqOperationAssessment":
          return (this.flag = 1);
        case "/RqSecurity":
          return (this.flag = 2);
        case "/RqSwitchPage":
          return (this.flag = 3);
        case "/RqDesulphurization":
          return (this.flag = 5);
      }
    } else if (deviceInfo.type == "jrl") {
      footerarr[1].name = "运行考核";
      footerarr[2].name = "安全设置";
      footerarr[3].name = "切换画面";
      footerarr[4].name = "常用设置";
      //  footerarr[5].name = "客服系统"
      this.fooerList = footerarr;
      switch (this.$route.path) {
        case "/Jrindex":
          return (this.flag = 0);
        case "/Jryxpj":
          return (this.flag = 1);
        case "/Jraqsz":
          return (this.flag = 2);
        case "/Jrswitch":
          return (this.flag = 3);
      }
    } else if (deviceInfo.type == "cfb") {
      footerarr[1].name = "脱硫脱硝";
      footerarr[2].name = "运行考核";
      footerarr[3].name = "切换画面";
      footerarr[4].name = "安全设置";
      footerarr[5].name = "常用设置";
      footerarr[6].name = "数据统计";
      // footerarr[7].name = "客服"
      switch (this.$route.path) {
        case "/Cfbindex":
          return (this.flag = 0);
        case "/CfbDesulphurization":
          return (this.flag = 1);
        case "/CfbOperationAssessment":
          return (this.flag = 2);
        case "/CfbSwitchPage":
          return (this.flag = 3);
        case "/CfbSecurity":
          return (this.flag = 4);
        case "/CfbDataStatistics":
          return (this.flag = 6);
      }
    } else if (deviceInfo.type == "mfl") {
      footerarr[1].name = "制粉流程";
      footerarr[2].name = "二次风";
      footerarr[3].name = "运行考核";
      footerarr[4].name = "切换画面";
      footerarr[5].name = "安全设置";
      footerarr[6].name = "脱硫脱硝";
      footerarr[7].name = "客服";
      switch (this.$route.path) {
        case "/Mfindex":
          return (this.flag = 0);
        case "/MFZFXT":
          return (this.flag = 1);
        case "/MFECF":
          return (this.flag = 2);
        case "MFYXPJ":
          return (this.flag = 3);
        case "/MFSwitch":
          return (this.flag = 4);
        case "/MFSecurity":
          return (this.flag = 5);
        case "/MFTLTX":
          return (this.flag = 6);
      }
    } else if (deviceInfo.type == "fyf") {
      footerarr[1].name = "一键启停";
      footerarr[2].name = "";
      footerarr[3].name = "";
      footerarr[4].name = "";
      switch (this.$route.path) {
        case "/Fyindex":
          return (this.flag = 0);
        case "/FyYjqt":
          return (this.flag = 1);
      }
    } else if (deviceInfo.type == "jhf") {
      // let isTrue = deviceInfo.project.includes('中盐吉兰泰')
      //  footerarr[1].name = !isTrue ? "燃烧流程2" : ''
      //  footerarr[2].name = !isTrue ? "燃烧流程3" : ''
      //  footerarr[3].name = ""
      //  footerarr[4].name = ""
      footerarr[0].name = "流程图";
      footerarr[1].name = "流程图2";
      footerarr[2].name = "流程图3";
      footerarr[3].name = "运行评价";
      footerarr[4].name = "";
      switch (this.$route.path) {
        case "/Jhindex":
          return (this.flag = 0);
        case "/jhindex2":
          return (this.flag = 1);
        case "/jhindex3":
          return (this.flag = 2);
        case "/jhxjpj":
          return (this.flag = 3);
      }
      // if(isTrue){
      //      switch(this.$route.path){
      //         case '/Jhindex_Zy':
      //         return this.flag = 0
      //     }
      // }else{

      // }
    } else if (deviceInfo.type == "jlt") {
      let isTrue = deviceInfo.project.includes("和隆优化仿真");
      if (isTrue) {
        footerarr[0].name = "精馏系统";
        footerarr[1].name = "反应釜系统";
        footerarr[2].name = "公用系统";
        footerarr[3].name = "切换画面";
        footerarr[4].name = "物料能耗";
        switch (this.$route.path) {
          case "/6DjlIndex":
            return (this.flag = 0);
          case "/6DfyfIndex":
            return (this.flag = 1);
          case "/6Dpub":
            return (this.flag = 2);
          case "/6Dqhhm":
            return (this.flag = 3);
          case "/6Dwlnh":
            return (this.flag = 4);
        }
      } else {
        footerarr[0].name = "精馏系统";
        footerarr[1].name = "运行评价";
        footerarr[2].name = "";
        footerarr[3].name = "";
        footerarr[4].name = "";
        switch (this.$route.path) {
          case "/Jlindex":
            return (this.flag = 0);
          case "/Jlyxpj":
            return (this.flag = 1);
        }
      }
    } else if (deviceInfo.type == "gz") {
      if (deviceInfo.project.includes("四川永祥")) {
        footerarr[0].name = "干燥流化床";
        footerarr[1].name = "干燥旋风床";
        footerarr[2].name = "运行评价";
        footerarr[3].name = "";
        footerarr[4].name = "";
        switch (this.$route.path) {
          case "/GzIndex":
            return (this.flag = 0);
          case "/GzIndex1":
            return (this.flag = 1);
          case "/Gzyxpj":
            return (this.flag = 2);
        }
      } else if (deviceInfo.project.includes("中盐吉兰泰化")) {
        footerarr[0].name = "干燥流化床A";
        footerarr[1].name = "干燥流化床B";
        footerarr[2].name = "运行评价";
        footerarr[3].name = "";
        footerarr[4].name = "";
        switch (this.$route.path) {
          case "/GzjltA":
            return (this.flag = 0);
          case "/GzjltB":
            return (this.flag = 1);
          case "/Gzyxpjjlt":
            return (this.flag = 2);
        }
      }
    }
  },
  mounted() {},
  watch: {},
  methods: {
    // 切换组件，并添加样式
    toLink(i, data) {
      let obj = Object.keys(data);
      if (obj.length != 0) {
        this.flag = i;
        if (this.authInfo.length != 0) {
          if (!this.authInfo[1]) {
            let str = this.authInfo[0];

            // 判断是否为调试还是查看权限
            if (str.charAt(str.length - 1) == "r") {
              this.see = false;
            }
          } else {
            this.see = true;
          }
        }
        if (this.deviceInfo.type == "rfl") {
          switch (i) {
            case 0:
              return this.$router.push({ path: "/index" });
            case 1:
              return this.authtips(1, "rfl");
            case 3:
              return this.authtips(3, "rfl");
            case 2:
              return this.$router.push({ path: "/Evaluate" });
          }
        } else if (this.deviceInfo.type == "rql") {
          switch (i) {
            case 0:
              return this.$router.push({ path: "/Rqindex" });
            case 1:
              return this.$router.push({ path: "/RqOperationAssessment" });
            case 2:
              return this.$router.push({ path: "/RqSecurity" });
            case 3:
              return this.$router.push({ path: "/RqSwitchPage" });
            case 5:
              return this.$router.push({ path: "/RqDesulphurization" });
          }
        } else if (this.deviceInfo.type == "jrl") {
          switch (i) {
            case 0:
              return this.$router.push({ path: "/Jrindex" });
            case 1:
              return this.$router.push({ path: "/Jryxpj" });
            case 2:
              return this.$router.push({ path: "/Jraqsz" });
            case 3:
              return this.$router.push({ path: "/Jrswitch" });
          }
        } else if (this.deviceInfo.type == "cfb") {
          switch (i) {
            case 0:
              return this.$router.push({ path: "/Cfbindex" });
            case 1:
              return this.$router.push({ path: "/CfbDesulphurization" });
            case 2:
              return this.$router.push({ path: "/CfbOperationAssessment" });
            case 3:
              return this.$router.push({ path: "/CfbSwitchPage" });
            case 4:
              return this.$router.push({ path: "/CfbSecurity" });
            case 6:
              return this.$router.push({ path: "/CfbDataStatistics" });
          }
        } else if (this.deviceInfo.type == "mfl") {
          switch (i) {
            case 0:
              return this.$router.push({ path: "/Mfindex" });
            case 1:
              return this.$router.push({ path: "/MFZFXT" });
            case 2:
              return this.$router.push({ path: "/MFECF" });
            case 3:
              return this.$router.push({ path: "/MFYXPJ" });
            case 4:
              return this.$router.push({ path: "/MFSwitch" });
            case 5:
              return this.$router.push({ path: "/MFSecurity" });
            case 6:
              return this.$router.push({ path: "/MFTLTX" });
          }
        } else if (this.deviceInfo.type == "fyf") {
          switch (i) {
            case 0:
              return this.$router.push({ path: "/Fyindex" });
            case 1:
              return this.$router.push({ path: "/FyYjqt" });
          }
        } else if (this.deviceInfo.type == "jhf") {
          switch (i) {
            case 0:
              return this.$router.push({ path: "/Jhindex" });
            case 1:
              return this.$router.push({ path: "/Jhindex2" });
            case 2:
              return this.$router.push({ path: "/Jhindex3" });
            case 3:
              return this.$router.push({ path: "/Jhyxpj" });
          }
          //      let isTrue = this.deviceInfo.project.includes('中盐吉兰泰')
          //      if(isTrue){
          //      switch(i){
          //     case 0:
          //     return this.$router.push({ path: '/Jhindex_Zy' })
          // }
          //      }else{

          //      }
        } else if (this.deviceInfo.type == "jlt") {
          let isTrue = this.deviceInfo.project.includes("和隆优化仿真");
          if (isTrue) {
            switch (i) {
              case 0:
                return this.$router.push({ path: "/6DjlIndex" });
              case 1:
                return this.$router.push({ path: "/6DfyfIndex" });
              case 2:
                return this.$router.push({ path: "/6Dpub" });
              case 3:
                return this.$router.push({ path: "/6Dqhhm" });
              case 4:
                return this.$router.push({ path: "/6Dwlnh" });
            }
          } else {
            switch (i) {
              case 0:
                return this.$router.push({ path: "/Jlindex" });
              case 1:
                return this.$router.push({ path: "/Jlyxpj" });
            }
          }
        } else if (this.deviceInfo.type == "gz") {
          if (this.deviceInfo.project.includes("四川永祥")) {
            switch (i) {
              case 0:
                return this.$router.push({ path: "/GzIndex" });
              case 1:
                return this.$router.push({ path: "/GzIndex1" });
              case 2:
                return this.$router.push({ path: "/Gzyxpj" });
            }
          }else if(this.deviceInfo.project.includes("中盐吉兰泰化")){
             switch (i) {
              case 0:
                return this.$router.push({ path: "/GzjltA" });
              case 1:
                return this.$router.push({ path: "/GzjltB" });
              case 2:
                return this.$router.push({ path: "/Gzyxpjjlt" });
            }
          }
        }
      } else {
        return this.$message.error("暂无内容");
      }
      this.$bus.$emit("sendMsg", this.flag, this.commotent);
    },
    authtips(i, type) {
      if (type == "rfl") {
        if (this.see) {
          if (i == 3) {
            this.$router.push({ path: "/switchPage" });
          } else if (i == 1) {
            this.$router.push({ path: "/Security" });
          }
        } else {
          this.$message.error("暂无权限");
          if (this.$route.path == "/Evaluate") {
            this.flag = 2;
          } else {
            this.flag = 0;
          }
        }
      }
    },
  },
  components: {},
  destroyed() {
    this.$bus.$off("sendMsg");
  },
};
</script>

<style scoped lang="scss">
.footer_flex {
  width: 100vw;
  height: inherit;
  position: absolute;
  bottom: 0;
  .footer_tab {
    width: 10vw;
    height: 6vh;
    color: rgba(255, 255, 0255, 0.8);
    text-align: center;
    line-height: 6vh;
    box-sizing: border-box;
    background: rgba(11, 82, 111, 0.8);
    border-radius: 5px;
    margin-right: 3px;
    font-family: MicrosoftYaHei;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 6vh;
    letter-spacing: 0vh;
    color: #6ca6bc;
    cursor: pointer;
  }
  .current {
    background: #1e97c8;
    color: #fff;
  }
}
</style>
