<template>
    <el-container class="logincon">
         <transition name="slide"><router-view /></transition>
    </el-container>
</template>
<script>
    export default {
        name: "Layout",
        components: {
        },
        created() {
        },
        mounted() {},
        computed: {
        },

        watch: {},
        methods: {}
    }
</script>
<style lang="scss">
.logincon{
    width: 100%;
    height: 100vh;
    background: url('~@/assets/images/loginbg.jpeg') no-repeat 0 0;
    background-size: cover;
    overflow: hidden;
}
</style>
